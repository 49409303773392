<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
            <span class="subtitle-1">{{ $t('innerSideNav.lgWorksheet') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-6">
            <v-row justify="end" class="ma-0">
              <v-col cols="12" sm="6" class="pa-0">
                <v-row class="ma-0">
                  <v-btn color="primary"
                         class="mr-3"
                         @click="seeWorksheetDetails('uj-megrendeles')">
                    {{ $t('button.createNew') }}
                  </v-btn>
                  <v-btn color="primary"
                         :disabled="selectedWorksheets.length < 1"
                         @click="getSelectedWorksheetsIds">
                    {{ $t('button.export') }}
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0">
                <v-text-field
                  v-model="search"
                  :aria-label="$t('search')"
                  :label="$t('search')"
                  filled
                  prepend-inner-icon="mdi-magnify"
                  persistent-hint
                  clearable
                  :hint="$t('inputMessages.searchHint')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              v-model="selectedWorksheets"
              show-select
              item-key="lgWorksheetId"
              :locale="$i18n.locale"
              :headers="tableHeader"
              :items="worksheets"
              :search="search"
              :sort-by="['dateOfOrder']"
              :sort-desc="['true']"
              :loading="loadingData"
              :loading-text="$t('loadingText')"
              :no-data-text="$t('noDataText.general')"
              :no-results-text="`${$t('noDataText.noSearchResult1')} ${search} ${$t('noDataText.noSearchResult2')}`"
            >
              <template v-slot:item.btn="{item}">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" class="mr-3" @click="seeWorksheetDetails(item.lgWorksheetId)" v-on="on">
                      <v-icon>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('button.view') }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" class="mr-3" @click="printWorksheetPDF(item.lgWorksheetId)" v-on="on">
                      <v-icon>
                        mdi-printer
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('button.print') }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" class="mr-3" @click="callSendWorksheet(item.lgWorksheetId)" v-on="on">
                      <template v-if="item.isEmailSent === 1">
                        <v-icon color="success">
                          mdi-email-check
                        </v-icon>
                      </template>
                      <template v-else>
                        <v-icon>
                          mdi-email-send
                        </v-icon>
                      </template>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('button.sendEmail') }}
                  </span>
                </v-tooltip>
                <!--v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" class="mr-3" @click="seeWorksheetDetails(item.serviceDetails.id)" v-on="on">
                      <v-icon>
                        mdi-microsoft-excel
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('button.export') }}
                  </span>
                </v-tooltip-->
                <!--v-btn text color="primary" @click="seeWorksheetDetails(item.serviceDetails.id)">
                  {{ $t('button.view') }}
                </v-btn>
                <v-btn text color="primary" @click="seeWorksheetDetails(item.serviceDetails.id)">
                  {{ $t('button.print') }}
                </v-btn-->
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </v-container>
</template>

<script>
import LoadingAndResponseDialog from 'src/components/dialogs/LoadingAndResponseDialog.vue';
import { getURA } from 'src/utils/jwtHelper';

export default {
  name: 'LGListPage',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    //
  },
  mounted() {
    //
  },
  async beforeMount() {
    // if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
    if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE' && await getURA().split(',').includes('LG')) {
      this.worksheets = await this.loadTableData();
      this.loadingData = false;
    } else {
      await this.$router.push({name: 'iranyitopult'});
    }
  },
  data() {
    return {
      search: null,
      selectedWorksheets: [],
      selectedWorksheetsToSend: [],
      tableHeader: [
        { text: this.$t('lg.wsid'), value: 'lgWorksheetNumber' },
        { text: this.$t('lg.dateOfOrder'), value: 'dateOfOrder' },
        { text: this.$t('lg.customerName'), value: 'customerName' },
        { text: this.$t('phoneNumber'), value: 'phone' },
        { text: this.$t('lg.productType'), value: 'deviceTypeName' },
        { text: this.$t('lg.installationAddress'), value: 'installationAddress' },
        { text: this.$t('lg.dateOfInstallation'), value: 'dateOfInstallation' },
        { text: '', value: 'btn' },
      ],
      worksheets: [],
      loadingData: true,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'loadingMessages.INPROGRESS_SENDLGWORKSHEET',
        successText: 'SUCCESS_MESSAGES.LGWORKSHEET_SEND',
        errorText: 'ERROR_MESSAGES.LGWORKSHEET_SEND',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('GetLGWorksheetList');
      // console.log(response.data);
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    seeWorksheetDetails(wsid) {
      this.$router.push({ name: 'lg-munkalap-reszletek', params: { ws: wsid } });
    },
    async getSelectedWorksheetsIds() {
      this.selectedWorksheetsToSend = [];
      this.selectedWorksheets.forEach((item) => {
        this.selectedWorksheetsToSend.push(item.lgWorksheetId);
      })
      // console.log('this.selectedWorksheetsToSend: ', this.selectedWorksheetsToSend);
      const response = await this.$store.dispatch('GetLGWorksheetsCSV', { worksheets: this.selectedWorksheetsToSend });
    },
    async printWorksheetPDF(wsid) {
      const response = this.$store.dispatch('GetLGWorksheetPDF', wsid);
    },
    async callSendWorksheet(wsid) {
      this.openLARDialog();
      const response = await this.$store.dispatch('SendLGWorksheetViaEmail', wsid);
      if (response.status === 200) {
        setTimeout(this.changeLARDialogContentToSuccess, 2200);
        this.loadingData = true;
        this.worksheets = await this.loadTableData();
        this.loadingData = false;
      } else {
        setTimeout(this.changeLARDialogContentToError, 2200);
      }
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
  },
};
</script>

<style scoped>

</style>
